<template>
    <div id="editsite" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="siteInformation"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="site_editViewSiteSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" id="Path-11" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                                                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("site_editSite") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right" autocomplete="off">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <h3 class="kt-section__title">1. {{ $t("site_siteInfo") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("site_siteName") }} *</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                    </div>
                                                                    <input id="editSiteNameInput" maxlength="80" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteName')" v-model="editSiteNameInputVal" @keyup="onChangeForm" />
                                                                </div>
                                                                <div v-if="!$v.editSiteNameInputVal.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.editSiteNameInputVal.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.editSiteNameInputVal.$params.minLen.min]) }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteName") }}</span>
                                                            </div>
                                                            <!-- <div class="col-lg-6">
                                                                <label class="">{{ $t("site_siteType") }} *</label>
                                                                <select id="editSiteTypeSelect" class="form-control kt-select2">
                                                                    To display placeholder
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteType") }}</span>
                                                            </div> -->
                                                            <div class="col-lg-6">
                                                                <label >{{ $t("site_siteType") }} *</label>
                                                                <div class="site-type">
                                                                    <div style="width: 100%" id="newSiteType" class="form-site-type form-column form-column-field">
                                                                        <input id="newSiteTypeInput" type="text" maxlength="80" @click="onShowListSiteType()" readonly="readonly" class="select-dropdown form-control cursorPointer" :placeholder="$t('site_detailInfoSiteType')" v-model="editSiteTypeInputVal"/>
                                                                        <i class="fa fa-angle-down icon-down"></i>
                                                                        <div class="list-site-type">
                                                                            <ul>
                                                                                <li v-for="siteType in this.siteTypes" :key="siteType.id" @click="onSelectedSiteType(siteType)" :class="editSiteTypeSelectVal.id === siteType.id && 'site-type-selected'">
                                                                                    <span v-if="siteType.isDefault">{{$t(siteType.name)}}</span>
                                                                                    <div v-else style="display: flex; justify-content: space-between; align-items: center;">
                                                                                        <div>{{siteType.name}}</div>
                                                                                        <div>
                                                                                            <button type="button" @click="onEditSiteType(siteType)" class="btn btn-outline-brand btn-sm btn-icon border-0 mr-2" style="height: 1.9rem; width: 1.9rem"><i class="la la-pencil"></i></button>
                                                                                            <button type="button" @click="onDeleteSiteType(siteType.id)" class="btn btn-outline-danger btn-sm btn-icon border-0" style="height: 1.9rem; width: 1.9rem"><i class="la la-trash"></i></button>    
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div @click="onShowAddNewSiteType()" class="btn btn-brand btn-bold btn-md btn-icon-h kt-margin-l-10 cursorPointer">
                                                                        <span style="font-size: 0.9rem;">
                                                                            <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                            {{ $t("site_createSiteType") }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.editSiteTypeSelectVal.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteType") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6" v-if="siteSecret">
                                                                <label class="">{{ $t("site_secret") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-key"></i></span>
                                                                    </div>
                                                                    <input id="editSiteSecretInput" type="text" class="form-control" v-model="siteSecret" disabled />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="">{{ $t("gtw_gatewayType") }}</label>
                                                                <div class="input-group">
                                                                    <input id="editBLEGatewayInput" type="text" class="form-control" v-model="BLEGatewaysType" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-12">
                                                                <label for="siteDescription">{{ $t("site_siteDescription") }}</label>
                                                                <textarea id="editSiteDescriptionTextArea" class="form-control" rows="3" v-model="editSiteDescriptionTextAreaVal" @keyup="onChangeForm"></textarea>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteDescription") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">2. {{ $t("site_siteAddressDetails") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("common_address") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                    </div>
                                                                    <input id="editSiteAddressInput" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteAddress')" v-model="editSiteAddressInputVal" @keyup="onChangeForm" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteAddress") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="">{{ $t("common_postCode") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-bookmark"></i></span>
                                                                    </div>
                                                                    <input id="editSitePostcodeInput" type="text" class="form-control" :placeholder="$t('site_inputInfoSitePostcode')" v-model="editSitePostcodeInputVal" @keyup="onChangeForm" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSitePostcode") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("common_city") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-map-marker"></i></span>
                                                                    </div>
                                                                    <input id="editSiteCityInput" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteCity')" v-model="editSiteCityInputVal" @keyup="onChangeForm" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteCity") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="">{{ $t("common_country") }}</label>
                                                                <select id="editSiteCountrySelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteCountry") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6" v-show='editSiteCountrySelectVal != "US"'>
                                                                <label>{{ $t("common_state") }}</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-map"></i></span>
                                                                    </div>
                                                                    <input id="editSiteStateInput" type="text" class="form-control" :placeholder="$t('site_inputInfoSiteState')" v-model="editSiteStateInputVal" @keyup="onChangeForm" />
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteState") }}</span>
                                                            </div>
                                                            <div class="col-lg-6" v-show='editSiteCountrySelectVal == "US"'>
                                                                <label>{{ $t("common_state") }}</label>
                                                                <select id="usaStateSelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteState") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="">{{ $t("common_timezone") }}</label>
                                                                <select id="editSiteTimezoneSelect" class="form-control kt-select2">
                                                                    <!-- To display placeholder -->
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("site_detailInfoSiteTimezone") }}</span>
                                                            </div>
                                                        </div>
                                                        <!--
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 class="kt-section__title">3. {{ $t("site_calibrationMode") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div class="form-group row form-group-last form-group-marginless">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <label class="kt-option cursorPointer" :class="calibrationMode === 'GATEWAYS_AND_BEACONS' ? 'tagBorderSelected' : 'tagBorderNotSelected'">
                                                                                <span class="kt-option__control">
                                                                                    <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                        <input v-on:change="onChangeForm" v-model="calibrationMode" id="editSite_GATEWAYS_AND_BEACONS_RadioButton" type="radio" name="GATEWAYS_AND_BEACONS_radio" value="GATEWAYS_AND_BEACONS" :checked="calibrationMode == 'GATEWAYS_AND_BEACONS'" />
                                                                                        <span></span>
                                                                                    </span>
                                                                                </span>
                                                                                <span class="kt-option__label">
                                                                                    <span class="kt-option__head">
                                                                                        <span class="kt-option__title" :class="calibrationMode === 'GATEWAYS_AND_BEACONS' ? 'tagLabelSelected' : 'tagLabelNotSelected'">{{ $t("site_calibrationModeWithGatewaysAndBeaconsTitle") }}</span>
                                                                                    </span>
                                                                                    <span class="kt-option__body">
                                                                                        {{ $t("site_calibrationModeWithGatewaysAndBeaconsDesc") }}
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <label class="kt-option cursorPointer" :class="calibrationMode === 'GATEWAYS_ONLY' ? 'tagBorderSelected' : 'tagBorderNotSelected'">
                                                                                <span class="kt-option__control">
                                                                                    <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                        <input v-on:change="onChangeForm" v-model="calibrationMode" id="editSite_GATEWAYS_ONLY_RadioButton" type="radio" name="GATEWAYS_ONLY_radio" value="GATEWAYS_ONLY" :checked="calibrationMode == 'GATEWAYS_ONLY'" />
                                                                                        <span></span>
                                                                                    </span>
                                                                                </span>
                                                                                <span class="kt-option__label">
                                                                                    <span class="kt-option__head">
                                                                                        <span class="kt-option__title" :class="calibrationMode === 'GATEWAYS_ONLY' ? 'tagLabelSelected' : 'tagLabelNotSelected'">{{ $t("site_calibrationModeWithGatewaysOnlyTitle") }}</span>
                                                                                    </span>
                                                                                    <span class="kt-option__body">
                                                                                        {{ $t("site_calibrationModeWithGatewaysOnlyDesc") }}
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>
                                                        <h3 class="kt-section__title">4. {{ $t("site_walkwayMode") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div class="form-group row form-group-last form-group-marginless">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <label class="kt-option cursorPointer" :class="walkwayMode === 'WITHOUT_WALKWAY' ? 'walkwayBorderSelected' : 'walkwayBorderNotSelected'">
                                                                                <span class="kt-option__control">
                                                                                    <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                        <input v-on:change="onChangeForm" v-model="walkwayMode" type="radio" id="newSite_WITHOUT_WALKWAY_RadioButton" name="WITHOUT_WALKWAY_radio" value="WITHOUT_WALKWAY" :checked="walkwayMode == 'WITHOUT_WALKWAY'" />
                                                                                        <span></span>
                                                                                    </span>
                                                                                </span>
                                                                                <span class="kt-option__label">
                                                                                    <span class="kt-option__head">
                                                                                        <span class="kt-option__title" :class="walkwayMode === 'WITHOUT_WALKWAY' ? 'walkwayLabelSelected' : 'walkwayLabelNotSelected'">{{ $t("site_walkwayModeWithoutWalkwayTitle") }}</span>
                                                                                    </span>
                                                                                    <span class="kt-option__body">
                                                                                        {{ $t("site_walkwayModeWithoutWalkwayDesc") }}
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <label class="kt-option cursorPointer" :class="walkwayMode === 'WITH_WALKWAY' ? 'walkwayBorderSelected' : 'walkwayBorderNotSelected'">
                                                                                <span class="kt-option__control">
                                                                                    <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                                        <input v-on:change="onChangeForm" v-model="walkwayMode" type="radio" id="newSite_WITH_WALKWAY_RadioButton" name="WITH_WALKWAY_radio" value="WITH_WALKWAY" :checked="walkwayMode == 'WITH_WALKWAY'" />
                                                                                        <span></span>
                                                                                    </span>
                                                                                </span>
                                                                                <span class="kt-option__label">
                                                                                    <span class="kt-option__head">
                                                                                        <span class="kt-option__title" :class="walkwayMode === 'WITH_WALKWAY' ? 'walkwayLabelSelected' : 'walkwayLabelNotSelected'">{{ $t("site_walkwayModeWithWalkwayTitle") }}</span>
                                                                                    </span>
                                                                                    <span class="kt-option__body">
                                                                                        {{ $t("site_walkwayModeWithWalkwayDesc") }}
                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->

                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 class="kt-section__title">3. {{ $t("site_siteImage") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div role="alert" class="alert alert-secondary">
                                                                <div class="alert-icon">
                                                                    <i class="flaticon-photo-camera kt-font-brand"></i>
                                                                </div>
                                                                <div class="alert-text">
                                                                    <span>{{ $t("site_siteImageHelp") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group" :class="isSuperAdmin() || isALEAdmin() ? 'row' : 'form-group-last row'">
                                                                <div class="col-lg-6">
                                                                    <label>{{ $t("common_fileBrowser") }}</label>
                                                                    <div class="custom-file">
                                                                        <input type="file" @change="handleFileSelected" ref="imageSiteFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectSiteImageInputFile" />
                                                                        <label class="custom-file-label textLeft" id="selectSiteImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                        <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                    </div>
                                                                </div>
                                                                <div v-show="isImageLoaded" class="col-lg-6">
                                                                    <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.imageToDisplay + ')' }"></div>
                                                                    <div class="kt-margin-b-10 kt-margin-t-10">{{ editSiteImageName }}</div>
                                                                    <div>
                                                                        <button id="newSiteRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="isSiteAdmin || isSuperAdmin || isALEAdmin">
                                                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                            <h3 class="kt-section__title">4. {{ $t("site_IQMConfiguration") }}</h3>

                                                            <div class="kt-section__body">
                                                                <!--begin:HelpIQM-->
                                                                <div role="alert" class="alert alert-secondary">
                                                                    <div class="alert-icon">
                                                                        <i class="flaticon2-list-1 kt-font-brand"></i>
                                                                    </div>
                                                                    <div class="alert-text">
                                                                        <ul class="marginB0">
                                                                            <li><strong class="fontMonospace">1 . </strong>
                                                                                {{ $t("site_IQMHelpL1_1") }}
                                                                                <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMServerVersionContent')" :data-original-title="$t('site_IQMServerVersionTitle')">
                                                                                    {{ $t("site_IQMHelpL1_2") }}
                                                                                </a>
                                                                                {{ $t("site_IQMHelpL1_3") }}
                                                                            </li>
                                                                            <li><strong class="fontMonospace">2 . </strong>
                                                                                {{ $t("site_IQMHelpL2_1") }}
                                                                                <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMServerAdminServicesContent')" :data-original-title="$t('site_IQMServerAdminServicesTitle')">
                                                                                    {{ $t("site_IQMHelpL2_2") }}
                                                                                </a>
                                                                                {{ $t("site_IQMHelpL2_3") }}
                                                                            </li>
                                                                            <li><strong class="fontMonospace">3 . </strong>
                                                                                {{ $t("site_IQMHelpL3") }}
                                                                            </li>
                                                                            <li><strong class="fontMonospace">4 . </strong>
                                                                                {{ $t("site_IQMHelpL4_1") }}
                                                                                <a class="kt-link" href="#" data-placement="right" data-toggle="kt-popover" data-html="true" title :data-content="$t('site_IQMApiEventFlowsContent')" :data-original-title="$t('site_IQMApiEventFlowsTitle')">
                                                                                    {{ $t("site_IQMHelpL4_2") }}
                                                                                </a>
                                                                                {{ $t("site_IQMHelpL4_3") }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <!--end:HelpIQM-->
                                                                <div class="form-group row validated">
                                                                    <div class="col-lg-6">
                                                                        <label>{{ $t("site_IQMUrl") }}</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"><i class="la la-server"></i></span>
                                                                            </div>
                                                                            <input id="newSiteIQMServerUrl" maxlength="255" type="url" class="form-control" :placeholder="$t('site_IQMUrlPlaceholder')" v-model="IQMServerUrl" :disabled="IQMServerIsVerify" @keyup="onChangeForm" />
                                                                        </div>
                                                                        <div v-if="!$v.IQMServerUrl.required" class="invalid-feedback">
                                                                            {{ $t("error_fieldIsRequired") }}
                                                                        </div>
                                                                        <div class="invalid-feedback" v-else-if="!$v.IQMServerUrl.url">
                                                                            {{ $t("error_invalidUrlMsg") }}
                                                                        </div>
                                                                        <span class="form-text text-muted">{{ $t("site_IQMUrlDetail") }}</span>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <label >{{ $t("site_IQMPort") }}</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"><i class="la la-support"></i></span>
                                                                            </div>
                                                                            <input id="newSiteIQMServerPort" min="0" type="number" class="form-control" :placeholder="$t('site_IQMPortPlaceholder')" v-model="IQMServerPort" :disabled="IQMServerIsVerify" @keyup="onChangeForm" />
                                                                        </div>
                                                                        <div v-if="!$v.IQMServerPort.required" class="invalid-feedback">
                                                                            {{ $t("error_fieldIsRequired") }}
                                                                        </div>
                                                                        <div class="invalid-feedback" v-else-if="!$v.IQMServerPort.integer || !$v.IQMServerPort.between">
                                                                            {{ $t("error_invalidAddressPort") }}
                                                                        </div>
                                                                        <span class="form-text text-muted">{{ $t("site_IQMPortDetail") }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row validated">
                                                                    <div class="col-lg-6">
                                                                        <label>{{ $t("site_IQMUserName") }}</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"><i class="la la-user"></i></span>
                                                                            </div>
                                                                            <input id="newSiteIQMServerUserName" maxlength="80" type="text" class="form-control" autocomplete="new-username" :placeholder="$t('site_IQMUserNamePlaceholder')" v-model="IQMServerUsername" :disabled="IQMServerIsVerify" @keyup="onChangeForm"/>
                                                                        </div>
                                                                        <div v-if="!$v.IQMServerUsername.required" class="invalid-feedback">
                                                                            {{ $t("error_fieldIsRequired") }}
                                                                        </div>
                                                                        <span class="form-text text-muted">{{ $t("site_IQMUsernameDetail") }}</span>
                                                                    </div>
                                                                    <div class="col-lg-6">
                                                                        <label>{{ $t("site_IQMPassword") }}</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"><i class="la la-key"></i></span>
                                                                            </div>
                                                                            <input id="newSiteIQMServerPassword" maxlength="80" type="password" class="form-control" autocomplete="new-password" :placeholder="$t('site_IQMPasswordPlaceholder')" v-model="IQMServerPassword" :disabled="IQMServerIsVerify" @keyup="onChangeForm" />
                                                                        </div>
                                                                        <div v-if="!$v.IQMServerPassword.required" class="invalid-feedback">
                                                                            {{ $t("error_fieldIsRequired") }}
                                                                        </div>
                                                                        <span class="form-text text-muted">{{ $t("site_IQMPasswordDetail") }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <button v-if="IQMServerIsVerify" id="newSiteResetIQMServerFormButton" @click="onResetIQMServerButton" type="button" class="btn btn-danger kt-margin-r-5">
                                                                            <i class="fa fa-undo kt-margin-t-2 kt-margin-r-5"></i>{{ $t("site_IQMResetIQMForm") }}
                                                                        </button>
                                                                        <button v-else id="newSiteTestIQMServerButton" @click="onTestIQMServerButton" type="button" :disabled="!IQMFormIsCorrect" class="btn btn-brand kt-margin-r-5">
                                                                            <i class="fa fa-check-circle kt-margin-t-2 kt-margin-r-5"></i>{{ $t("site_IQMTestConnection") }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div v-if="isSuperAdmin() || isALEAdmin()">
                                                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                            <h3 class="kt-section__title">5. {{ $t("site_siteEngine") }}</h3>
                                                            <div class="kt-section__body">
                                                                <div class="form-group form-group-last validated">
                                                                    <p class="kt-margin-b-30">{{ $t("site_siteEngineSelection") }}</p>
                                                                    <div class="kt-radio-list">
                                                                        <div v-for="engineMode in engineModeList" :key="engineMode" class="row ml-1 mb-3">
                                                                            <div>
                                                                                <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                                    <input type="radio" :id="'engineRadio_' + engineMode" name="engine" :value="engineMode" v-model="editSiteEngineMode" @change="onChangeForm" checked="checked" /> {{ $t(engineMode) }} <br />
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-9"  v-if="engineMode == 'HAN'" style="margin-top: -10px;">
                                                                                <select class="form-control kt-select2"  id="engineModeAlgoSelect2">
                                                                                    <!--option></option-->
                                                                                </select>
                                                                                <span v-if="engineMode == 'HAN'" class="form-text text-muted"> {{ $t("site_siteEngineAlgoDetailInfo") }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-else-if="isSiteAdmin && editSiteEngineMode === 'HAN'">
                                                            <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                            <h3 class="kt-section__title">5. {{ $t("site_siteEngineAlgo") }}</h3>
                                                            <div class="kt-section__body">
                                                                <div class="form-group form-group-last validated">
                                                                    <div class="kt-radio-list">
                                                                        <label v-for="engineAlgo in engineAlgoList" :key="engineAlgo" class="kt-radio kt-radio--bold kt-radio--brand">
                                                                            <input type="radio" :id="('engineAlgoRadio_' + engineAlgo)" name="engineAlgo" :value="engineAlgo" v-model="editSiteEngineAlgo" @change="onChangeForm" checked="checked" /> {{ $t(engineAlgo) }} <br />
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <button id="editSiteCreateButton" @click="onUpdateButton" type="button" :disabled="!enableSaveButton" class="btn btn-brand kt-margin-r-5">
                                                                <i class="la la-save"></i>
                                                                {{ $t("common_saveChanges") }}
                                                            </button>
                                                            <button id="editSiteCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, url, integer, between, requiredIf } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

import _ct from "countries-and-timezones";
const _siteTypes = require("../../constants/siteTypes");
const _orderBy = require("lodash.orderby");
const _usaStates = require('usa-states').UsaStates;

export default {
    name: "editsite",
    data() {
        console.log("Component(EditSite)::data() - called");
        return {
            countries: _ct.getAllCountries(),
            timezones: _ct.getAllTimezones(),
            usaStates: new _usaStates().states,
            basePath: process.env.VUE_APP_BACKEND_URL,
            editSiteId: this.$route.params.siteId,
            editSiteCountrySelectVal: "",
            editSiteTimezoneSelectVal: "",
            oldEditSiteTypeSelectVal: "",
            editSiteTypeSelectVal: "",
            editSiteTypeInputVal: "",
            editSiteNameInputVal: "",
            siteSecret: "",
            BLEGatewaysType: "",
            editSiteDescriptionTextAreaVal: "",
            editSiteAddressInputVal: "",
            editSitePostcodeInputVal: "",
            editSiteCityInputVal: "",
            editSiteStateInputVal: "",
            editSiteImage: "",
            editSiteImageName: "",
            imageToDisplay: "",
            imageFile: null,
            isImageLoaded: false,
            isFormDataChanged: false,
            calibrationMode: "",
            walkwayMode: "",
            engineModeList: ["HAN", "NEURAL_NETWORK", "ALE_ENGINE"],
            engineAlgoList: ["DEFAULT", "KNN_WITH_RSSI", "KNN_WITH_ATTENUATION_RSSI", "KNN_WITH_DISTANCE", "AP_RSSI"],
            editSiteEngineAlgo: "DEFAULT",
            editSiteEngineMode: "",
            IQMServerUrl: null,
            IQMServerPort: null,
            IQMServerUsername: null,
            IQMServerPassword: null,
            IQMFakePassword: "********************"
        };
    },
    created: function() {
        console.log("Component(EditSite)::created() - called with id: " + this.editSiteId);
        this.getSiteById(this.editSiteId);
        this.getAllSiteTypeOfSite(this.$route.params.siteId);
    },
    mounted: function() {
        console.log("Component(EditSite)::mounted() - Init metronic layout");
        KTLayout.init();
        // this.initSiteTypeSelect2();
        this.initSiteCountrySelect2();
        this.initSiteTimezoneSelect2();
        this.initUsaStatesSelect2();
        this.initEngineAlgoSelect2();
    },
    destroyed: function() {
        console.log("Component(EditSite)::destroyed() - called");
        this.resetSitesState();
        this.resetSiteTypesState();
    },
    validations: {
        editSiteNameInputVal: {
            required,
            minLen: minLength(2)
        },
        editSiteTypeSelectVal: {
            required
        },
        editSiteTimezoneSelectVal: {
            required
        },
        IQMServerUrl: {
            url,
            required: requiredIf(function() {
                return this.IQMServerUsername || this.IQMServerPassword || this.IQMServerPort;
            })
        },
        IQMServerPort: {
            integer,
            between: between(0, 65536),
            required: requiredIf(function() {
                return this.IQMServerUsername || this.IQMServerPassword || this.IQMServerUrl;
            })
        },
        IQMServerUsername: {
            required: requiredIf(function() {
                return this.IQMServerUrl || this.IQMServerPassword || this.IQMServerPort;
            })
        },
        IQMServerPassword: {
            required: requiredIf(function() {
                return this.IQMServerUrl || this.IQMServerUsername || this.IQMServerPort;
            })
        },
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentSite: function(site) {
            console.log("Component(EditSite)::watch(currentSite) - called with ", site);
            if (site) {
                this.editSiteId = site.id;
                this.editSiteCountrySelectVal = site.country;
                let validTimeZone = _ct.getTimezonesForCountry("US")[0].name;
                if (site.timezone && this.timezones[site.timezone]) {
                    // Valid timezone
                    validTimeZone = site.timezone;
                } else {
                    // Bad timezone (to set because it's required in validations)
                    // Set to country timezone
                    if (this.editSiteCountrySelectVal) {
                        let countryTimezones = _ct.getTimezonesForCountry(this.editSiteCountrySelectVal);
                        if (countryTimezones && countryTimezones[0]) {
                            validTimeZone = countryTimezones[0].name;
                        }
                    }
                }
                this.editSiteTimezoneSelectVal = validTimeZone;
                this.editSiteTypeSelectVal = site.siteType;
                this.oldEditSiteTypeSelectVal = site.siteType.name;
                this.editSiteTypeInputVal = site.siteType.isDefault ? i18n.t(site.siteType.name) : site.siteType.name;
                this.editSiteNameInputVal = site.name;
                this.siteSecret = site.BLEGatewaysType === "OMNIACCESS_OEM" ? site.siteSecret : "";
                this.BLEGatewaysType = i18n.t(site.BLEGatewaysType);
                this.editSiteDescriptionTextAreaVal = site.description;
                this.editSiteAddressInputVal = site.street;
                this.editSitePostcodeInputVal = site.zipCode;
                this.editSiteCityInputVal = site.city;
                this.editSiteStateInputVal = site.state;
                this.calibrationMode = site.calibrationMode;
                this.walkwayMode = site.isWalkwayActivated ? "WITH_WALKWAY" : "WITHOUT_WALKWAY";
                // Check IQM Server
                if (site.IQMConfiguration && site.IQMConfiguration.url !== null) {
                    this.IQMServerUrl = site.IQMConfiguration.url;
                    this.IQMServerPort = site.IQMConfiguration.port;
                    this.IQMServerUsername = site.IQMConfiguration.username;
                    this.IQMServerPassword = this.IQMFakePassword;
                    this.validateIQMServer();
                } else {
                    this.IQMServerUrl = null;
                    this.IQMServerPort = null;
                    this.IQMServerUsername = null;
                    this.IQMServerPassword = null;
                    this.invalidateIQMServer();
                }

                site.image ? (this.isImageLoaded = true) : (this.isImageLoaded = false);

                if (site.imageName) {
                    this.editSiteImageName = site.imageName;
                }
                if (site.image) {
                    const siteImage = site.image;
                    if (siteImage.includes("data:", 0) && siteImage.includes("base64")) {
                        // Old image (not yet uploaded)
                        this.imageToDisplay = siteImage;
                        this.editSiteImage = siteImage;
                    } else {
                        this.imageToDisplay = commonVueHelper.convertImageLinkToBase64(this.basePath + siteImage);
                        this.editSiteImage = commonVueHelper.convertImageLinkToBase64(this.basePath + siteImage);
                    }
                    this.isImageLoaded = true;
                } else {
                    this.isImageLoaded = false;
                }

                this.editSiteEngineMode = site.engineMode;
                this.editSiteEngineAlgo = site.engineAlgo || "DEFAULT";
                this.initEngineAlgoSelect2();

                if ($("#editSiteTypeSelect").data("select2")) {
                    $("#editSiteTypeSelect").val(site.type);
                    // Trigger change event and provide an additional param
                    $("#editSiteTypeSelect").trigger({ type: "change", params: { initialInit: true } });
                }

                if ($("#editSiteCountrySelect").data("select2")) {
                    $("#editSiteCountrySelect").val(site.country);
                    // Trigger change event and provide an additional param
                    $("#editSiteCountrySelect").trigger({ type: "change", params: { initialInit: true } });
                }

                if ($("#editSiteTimezoneSelect").data("select2")) {
                    $("#editSiteTimezoneSelect").val(validTimeZone);
                    // Trigger change event and provide an additional param
                    $("#editSiteTimezoneSelect").trigger({ type: "change", params: { initialInit: true } });
                }

                if (site.country == "US" && $("#usaStateSelect").data("select2")) {
                    $("#usaStateSelect").val(site.state);
                    // Trigger change event and provide an additional param
                    $("#usaStateSelect").trigger({ type: "change", params: { initialInit: true } });
                }
            }
        },

        user: function(user) {
            console.log("Component(EditSite)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // this.updateSiteTypeSelect2(user.preferredLanguage);
                this.initEngineAlgoSelect2();

                this.editSiteTypeInputVal = this.editSiteTypeSelectVal ?
                    this.editSiteTypeSelectVal.isDefault ?
                        i18n.t(this.editSiteTypeSelectVal.name) :
                        this.editSiteTypeSelectVal.name
                    : "";
            }
        },

        editSiteEngineMode: function(editSiteEngineMode) {
            console.log("Component(EditSite)::watch(engineMode) - called with ", editSiteEngineMode);
            if (editSiteEngineMode == "HAN") {
                $("#engineModeAlgoSelect2").removeAttr("disabled");
            } else {
                this.editSiteEngineAlgo = "DEFAULT";
                $("#engineModeAlgoSelect2").attr("disabled", "true");
            }
        },

        siteTypes: function(siteTypes) {
            console.log("Component(EditSite)::watch(siteTypes) - called with ", siteTypes);
            let newSiteType = siteTypes.find(siteType => {return siteType.isNewCreated});

            if (!newSiteType) {
                newSiteType = siteTypes.find(siteType => { return siteType.id ===  this.editSiteTypeSelectVal.id });
            }

            if (newSiteType) {
                this.editSiteTypeSelectVal = newSiteType;

                if (newSiteType.isDefault) {
                    this.editSiteTypeInputVal = i18n.t(newSiteType.name);
                } else {
                    this.editSiteTypeInputVal = newSiteType.name;
                }
            } 
        },

        editSiteTypeSelectVal: function() {
            if (this.oldEditSiteTypeSelectVal !== this.editSiteTypeSelectVal.name) {
                this.onChangeForm();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "user", "userRoleNameForSite", "IQMServerIsVerify", "siteTypes"]),

        orderedCountries: function() {
            return _orderBy(this.countries, "name");
        },

        orderedTimezones: function() {
            return _orderBy(this.timezones, "name");
        },

        orderedUsaStates: function() {
            return _orderBy(this.usaStates, "name");
        },

        isSiteAdmin() {
            return this.userRoleNameForSite === "ADMIN";
        },

        IQMFormIsCorrect: function() {
            return  this.IQMServerUrl && !this.$v.IQMServerUrl.$invalid && !this.$v.IQMServerUrl.$invalid && !this.$v.IQMServerUsername.$invalid && !this.$v.IQMServerPassword.$invalid && !this.$v.IQMServerPort.$invalid;
        },

        enableSaveButton: function() {
            if (!this.$v.$invalid && this.isFormDataChanged) {
                // Now verify if IQM Server is configured or not
                if (!this.IQMServerUrl && !this.IQMServerPort && !this.IQMServerUsername && !this.IQMServerPassword) {
                    return true;
                } else if (this.IQMFormIsCorrect && this.IQMServerIsVerify) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteById",
            "updateSite",
            "resetSitesState",
            "updateSiteProductionMode",
            "invalidateIQMServer",
            "validateIQMServer",
            "checkIQMServer",
            "getAllSiteTypeOfSite",
            "showSiteTypeModal",
            "deleteSiteTypeById",
            "resetSiteTypesState"
        ]),

        onShowAddNewSiteType() {
            console.log("Component(NewSite)::onShowAddNewSiteType() - called");
            this.showSiteTypeModal({type: "CREATE"});
        },

        onSelectedSiteType(siteType) {
            // setTimeout( () => {
            if(!this.isHandleEditOrDelete) {
                this.editSiteTypeSelectVal = siteType;
                if (siteType.isDefault) {
                    this.editSiteTypeInputVal = i18n.t(siteType.name);
                } else {
                    this.editSiteTypeInputVal = siteType.name;
                }
            }
            // }, 50);
            $(".list-site-type").hide();
        },

        onShowListSiteType() {
            $("#newSiteTypeInput").on('keydown keypress', function (e) {
                e.preventDefault();
            });
            if ($(".list-site-type").css('display') === 'none') {
                $(".list-site-type").show();
                this.registerEventSiteType();
            } else {
                $(".list-site-type").hide();
            }
            if($(".site-type-selected")[0]) {
                $(".list-site-type").animate({
                    scrollTop: $(".site-type-selected")[0].offsetTop - 150
                }, 0);
            }
        },

        registerEventSiteType() {
            if ($(".list-site-type").css('display') === 'block') {
                $("body").click(function (e) {
                    if (
                        (!$(".list-site-type").is(e.target) && $(".list-site-type").has(e.target).length === 0) &&
                        (!$("#newSiteTypeInput").is(e.target) && $("#newSiteTypeInput").has(e.target).length === 0)
                    ) {
                        $(".list-site-type").hide();
                    }
                });
            }
        },

        onEditSiteType(siteType) {
            this.isHandleEditOrDelete = true;
            this.showSiteTypeModal({type: "UPDATE", data: siteType });
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 100);
        },

        onDeleteSiteType(siteTypeId) {
            this.isHandleEditOrDelete = true;
            this.deleteSiteTypeById({ siteId: this.$route.params.siteId, siteTypeId: siteTypeId });
            if (siteTypeId === this.editSiteTypeSelectVal.id) {
                if (siteTypeId !== this.currentSite.siteType.id) {
                }
                this.editSiteTypeSelectVal = this.currentSite.siteType;
                this.editSiteTypeInputVal = this.currentSite.siteType.isDefault ? i18n.t(this.currentSite.siteType.name) : this.currentSite.siteType.name;
            }
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 50);
        },
        
        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(EditSite)::handleFileSelected() - called");
            if (this.$refs.imageSiteFile.files.length > 0) {
                if (this.$refs.imageSiteFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.editSiteImage = "";
                    this.editSiteImageName = "";
                    this.imageToDisplay = "";
                    this.isImageLoaded = false;
                    this.imageFile = null;
                } else {
                    this.imageFile = this.$refs.imageSiteFile.files[0];
                    this.editSiteImageName = this.imageFile.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = () => {
                        this.editSiteImage = reader.result;
                        this.imageToDisplay = this.editSiteImage;
                        this.isImageLoaded = true;
                        this.onChangeForm();
                    };
                }
            }
        },

        // Get array of units with key and tranlated unit
        getSorteUnitsData() {
            // Create array of units (with key and translated type)
            let dataArr = [];
            _.forEach(_siteTypes.list, key => {
                dataArr.push({
                    id: key, // Will be the value
                    text: i18n.t(key) // Will be the text displayed
                });
            });
            // Ascending sort order Select2 data by translated type
            const data = _.orderBy(dataArr, ["text"], ["asc"]);
            return data;
        },

        // Function called to init site types select2
        initSiteTypeSelect2() {
            var self = this;
            // Get ascending sort order Select2 unit data by translated type
            const data = self.getSorteUnitsData();
            // Init unit Select2
            commonVueHelper.destroySelect2($("#editSiteTypeSelect"));
            $("#editSiteTypeSelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteType"),
                    width: "100%",
                    data: data,
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.editSiteTypeSelectVal = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        updateSiteTypeSelect2(lang) {
            var self = this;
            // Get ascending sort order Select2 unit data by translated type
            const data = self.getSorteUnitsData();
            // Remove old options
            $("#editSiteTypeSelect").empty();
            // Unit translation update
            $("#editSiteTypeSelect")
                .select2({
                    language: lang,
                    placeholder: i18n.t("site_detailInfoSiteType"),
                    data: data
                })
                .val(self.editSiteTypeSelectVal)
                .trigger("change");
        },

        // Function called to init site timezones select2
        initSiteTimezoneSelect2() {
            var self = this;

            // Create array of data
            let dataArr = [];
            _.forEach(this.orderedTimezones, obj => {
                dataArr.push({
                    id: obj.name, // Will be the value
                    text: obj.name + " (GMT" + obj.utcOffsetStr + ")" // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#editSiteTimezoneSelect"));
            $("#editSiteTimezoneSelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteTimezone"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.editSiteTimezoneSelectVal = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        // Function called to init usa state select2
        initUsaStatesSelect2() {
            var self = this;

            // Create array of data
            let dataArr = [];
            _.forEach(this.orderedUsaStates, obj => {
                dataArr.push({
                    id: obj.name, // Will be the value
                    text: obj.name // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#usaStateSelect"));
            $("#usaStateSelect")
                .select2({
                    placeholder: i18n.t("site_detailUsaState"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.editSiteStateInputVal = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        // Function called to init site country select2
        initSiteCountrySelect2() {
            var self = this;

            // Create array of data
            let dataArr = [];
            _.forEach(this.orderedCountries, obj => {
                dataArr.push({
                    id: obj.id, // Will be the value
                    text: obj.name // Will be the text displayed
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#editSiteCountrySelect"));
            $("#editSiteCountrySelect")
                .select2({
                    placeholder: i18n.t("site_detailInfoSiteCountry"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataArr
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.editSiteCountrySelectVal = $(this).val();
                    if (self.editSiteCountrySelectVal == "US" && !event.params) {
                        self.editSiteStateInputVal = "";
                    }
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                    self.onCountrySelected(event);
                });
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.editSiteImage = "";
            this.editSiteImageName = "";
            this.imageToDisplay = "";
            this.isImageLoaded = false;
            this.imageFile = null;
            // Force clear of input file to be able to select the same image again
            $("#selectSiteImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
            // Set change data
            this.onChangeForm();
        },

        // Function called when user select a new country
        onCountrySelected: function(event) {
            // When a new country is selected, update the selected timezone value with the corresponding country timezone
            let countryTimezones = _ct.getTimezonesForCountry(event.target.value);
            if (countryTimezones && countryTimezones[0]) {
                this.editSiteTimezoneSelectVal = countryTimezones[0].name;
                $("#editSiteTimezoneSelect").val(this.editSiteTimezoneSelectVal);
                if (event.params) {
                    $("#editSiteTimezoneSelect").trigger({ type: "change", params: event.params });
                } else {
                    $("#editSiteTimezoneSelect").trigger("change");
                }
            }
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(EditSite)::onCancel() - called");
            this.$router.push({ name: "siteOverview", params: { siteId: this.editSiteId, fromAction: "cancelButton" } });
        },

        // Function called when user click on the "Create site" button
        onUpdateButton() {
            console.log("Component(EditSite)::onUpdateButton() - called");
            let siteData = {
                id: this.editSiteId,
                name: this.editSiteNameInputVal,
                siteTypeId: this.editSiteTypeSelectVal.id,
                description: this.editSiteDescriptionTextAreaVal,
                street: this.editSiteAddressInputVal,
                city: this.editSiteCityInputVal,
                zipCode: this.editSitePostcodeInputVal,
                state: this.editSiteStateInputVal,
                country: this.editSiteCountrySelectVal,
                timezone: this.editSiteTimezoneSelectVal,
                image: this.editSiteImage,
                imageName: this.editSiteImageName,
                calibrationMode: this.calibrationMode,
                isWalkwayActivated: this.walkwayMode === "WITH_WALKWAY" ? true : false,
                engineMode: this.editSiteEngineMode,
                engineAlgo: this.editSiteEngineMode === "HAN" ? this.editSiteEngineAlgo : "DEFAULT"
            };

            // Add IQM Server configuration
            if (this.IQMServerUrl && this.IQMServerUsername && this.IQMServerPassword && this.IQMFormIsCorrect) {
                siteData["IQMConfiguration"] = {
                    url: this.IQMServerUrl,
                    port: this.IQMServerPort,
                    username: this.IQMServerUsername
                };
                // Verify if password change
                if (this.IQMServerPassword !== this.IQMFakePassword) {
                    siteData.IQMConfiguration.password = this.IQMServerPassword;
                }

            } else {
                siteData["IQMConfiguration"] = null;
            }

            if ((this.isSuperAdmin() || this.isALEAdmin()) && this.currentSite.isInProduction && this.currentSite.engineMode !== this.editSiteEngineMode) {
                siteData.engineMode = this.editSiteEngineMode;
                // Unset backend production mode in case of engine mode change
                this.updateSiteProductionMode({
                    id: this.editSiteId,
                    isInProduction: false,
                    nextAction: "EDIT_SITE",
                    metadata: siteData
                });
            } else {
                // Update the site in the backend
                this.updateSite(siteData);
            }

        },

        onChangeForm() {
            this.isFormDataChanged = true;
        },

        isSuperAdmin() {
            if (this.user) {
                return this.user.isSuperAdmin;
            }
            return false;
        },

        isALEAdmin() {
            if (this.user) {
                return this.user.isALEAdmin;
            }
            return false;
        },

        // Function to test if the IQM server is available
        onTestIQMServerButton() {
            const payload = {
                url: this.IQMServerUrl,
                port: this.IQMServerPort,
                username: this.IQMServerUsername,
                password: this.IQMServerPassword
            };
            this.checkIQMServer(payload);
        },

        // Function to reset the form of IQM server configuration
        onResetIQMServerButton() {
            this.IQMServerUrl = null;
            this.IQMServerPort = null;
            this.IQMServerUsername = null;
            this.IQMServerPassword = null;
            this.invalidateIQMServer();
            this.onChangeForm();
        },

        // Funcion used to init the selection of radio
        initEngineAlgoSelect2() {
            var self = this;
            // Get ascending sort order Select2 unit data by translated type
            let dataArr = [];
            _.forEach(self.engineAlgoList, key => {
                dataArr.push({
                    id: key, // Will be the value
                    text: i18n.t(key) // Will be the text displayed
                });
            });
            // Ascending sort order Select2 data by translated type
            const data = _.orderBy(dataArr, ["text"], ["asc"]);
            // Remove old options
            $("#engineModeAlgoSelect2").empty();
            // Unit translation update
            $("#engineModeAlgoSelect2")
                .select2({
                    width: "50%",
                    placeholder: i18n.t("site_siteEngineAlgoPlaceholder"),
                    data: data,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    }
                })
                .val(this.editSiteEngineAlgo)
                .trigger("change")
                .on("change", function(event) {
                    if (self.editSiteEngineMode == "HAN") {
                        self.editSiteEngineAlgo = $(this).val();
                        if (self.currentSite && self.currentSite.engineAlgo !== self.editSiteEngineAlgo) {
                            // Trigger change form only if its a manual action from the user, not the initial init !
                            self.onChangeForm();
                        }
                    }
                });
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    filters: {},

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditSite", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.textLeft {
    text-align: left !important;
}
.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}

.site-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.form-site-type {
    position: relative;
}

.icon-down {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #b3b7c0;
}

.list-site-type {
    width: 100%;
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid #d2d5dc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 89;
    cursor: pointer;
    display: none;
}

.list-site-type ul{
    margin-bottom: 0px;
}
.list-site-type ul li {
    padding: 5px 15px;
}

.list-site-type ul li:hover {
    background: #E1E1E4;
}

.site-type-selected {
    background: #E1E1E4;
}
</style>
